
.react-flow {
  --xy-node-border-radius-default: 12px;
  --xy-node-border-default: 1px solid #E0E0E0;
  --xy-handle-border-color-default: #E0E0E0 ;
  --xy-handle-background-color-default: #F5F5F5;
  --xy-theme-selected: #757575;
  --xy-theme-edge-hover: #757575;
}

.react-flow__node {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  align-content: center;
  font-size: 14px;
  font-family: 'Montserrat';
  flex-direction: column;
  color: #757575;
}
